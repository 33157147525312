import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgAbdichtung01 from "../../assets/leistungen/abdichtung01.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung Title="Bauwerksabdichtung" Content="" />
    <LeistungBox
      Color="gray"
      Image={imgAbdichtung01}
      IsImageLeft={true}
      Title="Bautenschutz rundum"
      Content="Mit einer Kellerabdichtung aus Bitumen- oder Kunststoffbahnen „steht“ das ganze Gebäude in einer geschlossenen, dichten Wanne, die das Bauwerk allseitig umschließt. Die Wanne besteht aus einer Sohlen- und einer Wandabdichtung. Entscheidend ist die fachgerechte Verbindung zwischen den beiden Flächen, daher sollte die Abdichtungen immer aus den gleichen Materialien erstellt werden, um die dauerhaft dichte Verbindung sicherzustellen. Hier besteht einer der vorteilhaften Hauptunterschiede zu Kombinationsbauweisen aus wasserundurchlässiger Bodenplatte und einer ausschließlichen Wandabdichtung. Die Materialeigenschaften der Kunststoff- und Bitumenbahnen sichern eine dem Gebäude entsprechende Flexibilität und Standfestigkeit. Das hochwertige Bahnenmaterial widersteht in Verbindung mit einer vorgestellten Wärmedämmung oder Schutzlage problemlos auch stärkeren mechanischen Beanspruchungen, wie sie bei der späteren Verfüllung der Baugrube mit Erdreich auftreten. Vielfach wird der Keller aus wasserundurchlässigem WU-Beton erstellt. Sollen diese Kellerräume später genutzt werden oder sollen weitere Anforderungen von trockenem Raumklima und die Vermeidung von Tauwasser gestellt werden, so ist eine zusätzliche Abdichtung auf der Außenwand erforderlich. Ein Keller mit einer Wannenabdichtung und zusätzlicher Wärmedämmung macht Kellerräume wohnlich und für vielerlei anspruchsvolle individuelle Nutzungen verwendbar."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
